import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { useDashboardStore } from "@/store/modules/DashboardModule";
import { useUserStore } from "@/store/modules/UserModule";

declare module "vue-router" {
  interface RouteMeta {
    // Access level
    superAdminOnly?: boolean;
    organizationOnly?: boolean; // Super admin access is also allowed
    individualOnly?: boolean;
    devOnly?: boolean;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/v",
    component: () => import("@/layout/DevLayout.vue"),
    children: [
      {
        path: "/v/dev/feed/user",
        name: "devUserFeed",
        component: () => import("@/views/DevFeedUser.vue"),
        meta: { devOnly: true },
      },
      {
        path: "/v/dev/feed/groups",
        name: "devGroupFeeds",
        component: () => import("@/views/DevFeedGroups.vue"),
        meta: { devOnly: true },
      },
      {
        path: "/v/dev/active/session",
        name: "devSession",
        component: () => import("@/views/DevSession.vue"),
        meta: { devOnly: true },
      },
      {
        path: "/v/dev/logs",
        name: "logs",
        component: () => import("@/views/DevLogs.vue"),
        meta: { devOnly: true },
      },
      {
        path: "/v/dev/installationLogs",
        name: "installationLogs",
        component: () => import("@/views/DevInstallationLogs.vue"),
        meta: { devOnly: true },
      },
    ],
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        beforeEnter: (to, from, next) => {
          // TODO: Redirect based on sessionStorage
          // If we don't know then we just need to wait?

          return next({
            name: store.getters.isIndividualUser
              ? "userDashboard"
              : "adminDashboard",
          });
        },
      },
      {
        path: "/a/dashboard",
        name: "adminDashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: { organizationOnly: true },
      },
      {
        path: "/u/dashboard",
        name: "userDashboard",
        component: () => import("@/views/UserDashboard.vue"),
        meta: { individualOnly: true },
      },
      {
        // For now just the one page with switchable report types until common functionality is understood,
        // but some report types will be quite different - inputs, outputs, etc, and could just be their own
        // page, however the data gathering steps, layout, pdf generation, etc are also reusable.
        // .
        path: "/a/reports/:reportType",
        name: "reports",
        component: () => import("@/views/Reports.vue"),
        meta: { superAdminOnly: true, organizationOnly: true },
        props: true,
      },
      {
        path: "/a/users",
        name: "users",
        component: () => import("@/views/Users.vue"),
        meta: { organizationOnly: true },
      },
      {
        path: "/a/organizations",
        name: "organizations",
        component: () => import("@/views/Organizations.vue"),
        meta: { superAdminOnly: true },
        // beforeEnter: (to, from, next) => {
        //   if (!store.getters.currentUser.isAdmin) {
        //     return next("/dashboard");
        //   }
        //   return next();
        // },
      },
      {
        path: "/a/groups",
        name: "groups",
        component: () => import("@/views/Groups.vue"),
        meta: { superAdminOnly: true },
      },
      {
        path: "/a/sessions",
        name: "sessions",
        component: () => import("@/views/Sessions.vue"),
      },
      {
        path: "/u/sessions",
        name: "userSessions",
        component: () => import("@/views/Sessions.vue"),
        props: () => ({
          individualView: true,
          userId: store.getters.currentUser.id,
        }),
        meta: { individualOnly: true },
      },
      {
        path: "/a/training",
        name: "training",
        component: () => import("@/views/Training.vue"),
        meta: { organizationOnly: true },
      },
      {
        path: "/a/procedures",
        name: "procedures",
        component: () => import("@/views/Procedures.vue"),
        meta: { superAdminOnly: true },
      },
      // {
      //   path: "/a/groupParticipationAdmin",
      //   name: "groupParticipationAdmin",
      //   component: () => import("@/views/GroupParticipation.vue"),
      // },
      {
        path: "/u/profile",
        name: "userProfile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        // beforeEnter: (to, from, next) => {
        //   return next("/u/profile/overview");
        // },
        // beforeEnter: (to, from, next) => {
        //   return next("/a/user/" + store.getters.currentUser.id + "/overview");
        // },
        props: () => ({
          id: store.getters.currentUser.id,
          individualView: true,
        }),
        //   default: true,
        //   id: route => {  }
        // }
        redirect: { name: "userProfile-overview" },
        meta: { individualOnly: true },
        children: [
          {
            path: "overview",
            name: "userProfile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
            props: true,
          },
          {
            path: "training",
            name: "userProfile-training",
            component: () =>
              import("@/views/crafted/pages/profile/Training.vue"),
            props: true,
          },
          {
            path: "sessions",
            name: "userProfile-sessions",
            component: () =>
              import("@/views/crafted/pages/profile/Sessions.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/a/procedure/:id",
        name: "procedure-overview",
        component: () => import("@/components/page-layouts/Procedure.vue"),
        props: true,
        children: [
          {
            path: "upload",
            name: "procedure-upload",
            component: () => import("@/views/crafted/pages/profile/Upload.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/a/user/:id",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        props: true,
        redirect: { name: "profile-overview" },
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
            props: true,
          },
          {
            path: "training",
            name: "profile-training",
            component: () =>
              import("@/views/crafted/pages/profile/Training.vue"),
            props: true,
          },
          {
            path: "sessions",
            name: "profile-sessions",
            component: () =>
              import("@/views/crafted/pages/profile/Sessions.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/apps/subscriptions/getting-started",
        name: "apps-subscriptions-getting-started",
        component: () =>
          import("@/views/apps/subscriptions/GettingStarted.vue"),
      },
      {
        path: "/apps/subscriptions/subscription-list",
        name: "apps-subscriptions-subscription-list",
        component: () =>
          import("@/views/apps/subscriptions/SubscriptionList.vue"),
      },
      {
        path: "/apps/subscriptions/add-subscription",
        name: "apps-subscriptions-add-subscription",
        component: () =>
          import("@/views/apps/subscriptions/AddSubscription.vue"),
      },
      {
        path: "/apps/subscriptions/view-subscription",
        name: "apps-subscriptions-view-subscription",
        component: () =>
          import("@/views/apps/subscriptions/ViewSubscription.vue"),
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/group-participation",
        name: "group-participation",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/GroupParticipation.vue"
          ),
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from) {
    // Scroll page to top on every route change, but skip
    // if destination full path has query parameters and differs in no other way from previous
    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split("?")[0] == from.fullPath.split("?")[0]) return;
    }

    // Handle scroll
    window.scrollTo(0, 0);
  },
});

// See https://stackoverflow.com/questions/50833763/configure-router-beforeeach#:~:text=In%20router.beforeEach%20%28%29%20you%20should%20check%20if%20the,need%20to%20redirect%20her%2Fhim%20to%20the%20login%20page.
router.beforeResolve((to, from, next) => {
  const dashboardStore = useDashboardStore();
  const userStore = useUserStore();

  // Redirect if we had to sign in, this in the query parameters
  let adjustedTo = {
    name: to.name,
    query: to.query,
  };
  // Or to.name?
  const haveRedirect = from.name == "sign-in" && to.query.redirect;
  if (haveRedirect) {
    const redirect: any = to.query.redirect;
    const newQuery = { ...to.query };
    delete newQuery.redirect;
    adjustedTo = {
      name: redirect,
      query: newQuery,
    };
  }

  // Set the filter if previously specified, otherwise assume it to be as before - and in fact then we need to update the query
  // string - alternatively each navigation would specify all of this
  if (!to.query.dateRange && from.query.dateRange) {
    return next({
      path: to.path,
      query: { ...adjustedTo.query, ...from.query },
    });
  } else if (!to.query.dateRange) {
    return next({
      path: to.path,
      query: { ...adjustedTo.query, dateRange: "last 28 days" },
    });
  } else if (haveRedirect && adjustedTo.name) {
    return next({
      name: adjustedTo.name,
      query: { ...adjustedTo.query, dateRange: "last 28 days" },
    });
  }

  // Set the filter
  // TODO: Perhaps the dashboard be listening to this instead - ?
  // Also we overwrite any other potential query parameters - but is that okay?
  dashboardStore.setFilter(to.query, false);

  // If the page is unallowed then we will show the default dashboard.
  // This will in fact perform two redirects then.
  // An individual page is allowed as along as they are not in super admin mode
  const isNotAllowed =
    (to.meta.organizationOnly && store.getters.isIndividualUser) ||
    ((to.meta.superAdminOnly || to.meta.devOnly) &&
      !store.getters.currentUser.isAdmin) ||
    (to.meta.individualOnly &&
      store.getters.currentUser.isAdmin &&
      !store.getters.currentUser.userData); // what if assisting?

  // If we go between individual and admin it does not require a page reload,
  // so we need to force a load of the stats again to get the results.
  if (!to.meta.individualOnly && !dashboardStore.dashboardIncludesResults) {
    dashboardStore.loadStats();
  }

  // Not sure on the above and below, but we do this for dev at least
  // This would typically occur if we were super admin and then reverted back and the current page
  // is not applicable. Some pages are applicable.
  if (
    (to.meta.superAdminOnly || to.meta.devOnly) &&
    store.getters.currentUser.id &&
    !store.getters.currentUser.isAdmin
  ) {
    console.log(
      "Not super admin - showing normal dashboard",
      store.getters.currentUser
    );
    return next({ name: "adminDashboard", query: adjustedTo.query });
  }

  // sign-in to redirect
  if (
    to.name == "sign-in" &&
    from.name &&
    from.name != "sign-in" &&
    !to.query.redirect
  ) {
    let signInParams = {};
    signInParams = { redirect: from.name };

    return next({
      path: to.path,
      query: { ...to.query, ...signInParams },
    });
  }

  // HRMM - The above may not have been set by the time we get here, do we need to hold off?
  // What do we do?
  // console.log("USER", store.getters.currentUser);

  // Redirect to the dashboard if a step is not allowed, just to make sure the test above doesn't lead to infinite recursion, we
  // ensure we don't do it for the dashboard.
  // Not yet - there is some late action happening which makes this confusing
  // if (isNotAllowed && to.name != "dashboard" && from.name != "dashboard") {
  //   return next({ name: "dashboard" });
  // }

  // Use the path /u/ or /a/ => isadmin, isIndividualPath
  // And we need to update our setting then to match it, and continue with this path
  // However, on "Show individual dashboard", it should reload the page with a different URL - should switch if possible else show the dashboard.
  // But our menus are based on this route as well!!

  // organizationPath

  // console.log(
  //   "Route changed ",
  //   to.matched.some((record) => record.meta.requiresAuth)
  // );

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG, {
    individualMode: to.meta.individualOnly,
    devMode: to.meta.devOnly,
  });

  // We can reach here simply by the user specifying a page they want, so we need to verify authorization
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  return next();
});

export default router;
