import axios from "axios";
import { defineStore } from "pinia";
import { ref, watch, nextTick } from "vue";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { DateTime } from "luxon";
import { useRoute, useRouter } from "vue-router";
import { now } from "moment";

export const useDashboardStore = defineStore("dashboard", () => {
  const sinceDate = ref<any>();

  const allStats = ref<any>();
  const profileOptions = ref<any>();

  // hospital, dateRange, includeAll
  const filter = ref<any>({ dateRange: "last 28 days" });

  const presentation = ref<any>({
    connectStatsExpanded: false,
    proceduresExpanded: false,
    proceduresByAttempts: true,
  });

  const isLoadingDashboard = ref<boolean>(false);
  const dashboardStartQuery = ref<DateTime>(false);

  const isLoadingError = ref<boolean>(false);
  const loadingErrorMessage = ref<any>("");

  const hasLoadedMinimal = ref<boolean>(false);
  const hasLoadedDashboard = ref<boolean>(false);
  const dashboardIncludesResults = ref<any>(false);

  const assistOrganizationId = ref<number>();
  const assistGroupId = ref<number>();

  // We must have a loaded reference in order to project the current organization
  // There is a setter to get to this point
  const assistOrganization = computed(() => {
    const org = allStats.value?.organizations?.find(
      (o) => o.id == assistOrganizationId.value
    );
    const groupInfo = allStats.value?.groups?.find(
      (g) => g.g.id == org?.o?.group
    );
    return org ? { ...org.o, id: org.id, groupInfo: groupInfo?.g } : null;
  });

  const assistGroup = computed(() => {
    const org = allStats.value?.groups?.find(
      (g) => g.g.id == assistGroupId.value
    );
    return org ? { ...org.g, id: assistGroupId.value } : null;
  });

  const store = useStore();

  const lastPeriod = computed(() => {
    return filter.value?.dateRange ?? "last 28 days";
  });

  function normalizeHospitalName(name) {
    return name
      ? name
          .toUpperCase()
          .replaceAll("-", "")
          .replaceAll("–", "")
          .replaceAll(",", "")
          .trim()
      : null;
  }

  const formattedPeriodShort = computed(() => {
    // See getPeriodResults
    // Round off to the nearest days / months / years
    // If >= 28 days make it a month
    // If >= 11 months round it off to a year

    const customPeriodStart =
      lastPeriod.value == "custom" && filter.value.dateStart
        ? DateTime.fromISO(filter.value.dateStart)
        : null;

    const periodEnd =
      lastPeriod.value == "custom" && filter.value.dateEnd
        ? DateTime.fromISO(filter.value.dateEnd).endOf("day")
        : DateTime.now();

    // Very rough filtering for now - potentially should go to the start of the month / week
    const periodStart =
      customPeriodStart ??
      (lastPeriod.value == "all"
        ? null
        : lastPeriod.value == "last 12 months"
        ? DateTime.now().minus({ months: 12 })
        : lastPeriod.value == "last 3 months"
        ? DateTime.now().minus({ months: 3 })
        : lastPeriod.value == "last 6 months"
        ? DateTime.now().minus({ months: 6 })
        : lastPeriod.value == "last 7 days"
        ? DateTime.now().minus({ days: 7 })
        : DateTime.now().minus({ days: 28 }));

    // Attempt to resolve as days, or otherwise as months
    const days = periodEnd
      .startOf("day")
      .diff(periodStart.startOf("day"), ["days"])
      .toObject();
    const weeks = periodEnd
      .startOf("day")
      .diff(periodStart.startOf("day"), ["weeks"])
      .toObject();
    if (days.days < 27) {
      days.days = Math.ceil(days.days + 1);
      if (weeks.weeks > 0) {
        weeks.weeks = Math.round(weeks.weeks);
        return `${weeks.weeks} week${weeks.weeks == 1 ? "" : "s"}`;
      } else {
        return `${days.days} day${days.days == 1 ? "" : "s"}`;
      }
    } else {
      const months = periodEnd
        .diff(periodStart, ["years", "months"])
        .toObject();

      months.months = Math.ceil(months.months);

      if (months.months > 11) {
        ++months.years;
        months.months = 0;
      }
      if (months.years > 0 && months.months == 0) {
        return months.years + ` year${months.years == 1 ? "" : "s"}`;
      } else if (months.years > 0 && months.months != 0) {
        return (
          months.years +
          ` year${months.years == 1 ? "" : "s"} ` +
          months.months +
          ` month${months.months == 1 ? "" : "s"}`
        );
      } else {
        return months.months + ` month${months.months == 1 ? "" : "s"}`;
      }
    }
  });

  const formattedPeriodDetails = computed(() => {
    // See getPeriodResults
    // Round off to the nearest days / months / years
    // If >= 28 days make it a month
    // If >= 11 months round it off to a year

    const customPeriodStart =
      lastPeriod.value == "custom" && filter.value.dateStart
        ? DateTime.fromISO(filter.value.dateStart)
        : null;

    const periodEnd =
      lastPeriod.value == "custom" && filter.value.dateEnd
        ? DateTime.fromISO(filter.value.dateEnd).endOf("day")
        : DateTime.now();

    // Very rough filtering for now - potentially should go to the start of the month / week
    const periodStart =
      customPeriodStart ??
      (lastPeriod.value == "all"
        ? null
        : lastPeriod.value == "last 12 months"
        ? DateTime.now().minus({ months: 12 })
        : lastPeriod.value == "last 3 months"
        ? DateTime.now().minus({ months: 3 })
        : lastPeriod.value == "last 6 months"
        ? DateTime.now().minus({ months: 6 })
        : lastPeriod.value == "last 7 days"
        ? DateTime.now().minus({ days: 7 })
        : DateTime.now().minus({ days: 28 }));

    if (periodStart.startOf("day") == periodEnd.startOf("day")) {
      return periodStart.toLocaleString(DateTime.DATE_SHORT);
    }

    return (
      periodStart.toLocaleString(DateTime.DATE_SHORT) +
      " - " +
      periodEnd.toLocaleString(DateTime.DATE_SHORT)
    );
  });

  const formattedPeriod = computed(() => {
    if (filter.value?.dateRange == "custom") {
      // At least cover the simple case
      if (filter.value?.dateStart && filter.value?.dateEnd) {
        const st = DateTime.fromISO(filter.value?.dateStart);
        const en = DateTime.fromISO(filter.value?.dateEnd);

        console.log(
          st,
          en,
          st.startOf("month").startOf("day") == st.startOf("day"),
          st.endOf("month").startOf("day") == en.startOf("day")
        );

        let ret = " using custom filter";
        if (
          st.hasSame(st.startOf("month"), "day") &&
          en.hasSame(st.endOf("month"), "day")
        ) {
          ret = ` in ${st.monthLong} ${st.year}`;
        }

        return ret;
      }
    }
    return lastPeriod.value == "all" ? "" : ` over ${lastPeriod.value}`;
  });

  const isAdmin = computed(() => {
    return user.value.isAdmin;
  });

  const zoneOptions = [
    {
      value: "NA",
      label: "North America",
    },
    {
      value: "ANZ",
      label: "Australia & New Zealand",
    },
    {
      value: "G",
      label: "Global",
    },
    {
      value: "U",
      label: "Unknown",
    },
  ];

  const defaultZoneCode = (country) => {
    if (!country || country == "") {
      return "U";
    }
    if (country == "AU" || country == "NZ") {
      return "ANZ";
    }
    if (country == "US" || country == "CA") {
      return "NA";
    }
    return "G";
  };

  const logs = ref<any>([]);

  const stats = computed(() => {
    // console.log("stats", allStats);
    if (!allStats.value) {
      return null;
    }

    const haveHospitalFilter =
      (filter.value?.hospital && filter.value.hospital != "") ?? false;

    // See https://onlinejsontools.com/url-encode-json although it ends up being written back without that.
    // We have an array of custom information in this one big property which ordinarily won't be used, hence why we smuggle it in this way.
    // eg [{"type":"vantari_trainer","user":"51qvw81jkz9l1gm9","dates":["2022-03-10","2022-12-14","2023-05-03","2023-08-01","2023-09-21"]}]
    const customAll = filter.value?.custom
      ? JSON.parse(filter.value?.custom)
      : null;
    // Only allow a single
    const custom = customAll?.length ? customAll[0] : null;

    if (custom?.type == "vantari_trainer") {
      allStats.value.users
        .filter((value) => value.id == custom.user)
        .forEach((value) => {
          value.actualId = value.user;
          value.id = "vantari";
        });
    }

    const organizations = (allStats.value.organizations ?? [])
      .filter(
        (org) =>
          !filter.value?.hospital ||
          normalizeHospitalName(filter.value?.hospital) ==
            normalizeHospitalName(org.o.name)
      )
      .filter(
        (org) =>
          !filter.value?.zone ||
          defaultZoneCode(org.o.country) == filter.value.zone
      )
      .sort((a, b) => a.o.name?.localeCompare(b.o.name));

    const useFilteredGroups = haveHospitalFilter || filter.value?.zone;
    const filteredGroups = useFilteredGroups
      ? (allStats.value.groups ?? []).filter(
          (g) =>
            !useFilteredGroups ||
            organizations.find((o) => o.o?.group == g.g.id) ||
            organizations.find((o) => o.id == g.g.organization)
        )
      : null;

    // TODO: Is this is the best spot?
    const filteredZoneOptions = zoneOptions.filter(
      (zone) => !filter.value.zone || zone.value == filter.value.zone
    );

    const users = useFilteredGroups
      ? allStats.value.users.filter(
          (user) =>
            user.id == "vantari" ||
            !filter.value?.hospital ||
            normalizeHospitalName(user.hospital) ==
              normalizeHospitalName(filter.value.hospital) ||
            filteredGroups.find((g) => g.g.users?.find((u) => u == user.id)) ||
            filteredGroups.find((g) => g.g.admins?.find((u) => u == user.id))
        )
      : filter.value?.includeUnverifiedUsers ?? true
      ? allStats.value.users
      : allStats.value.users.filter((user) => user.isVerified);

    // TODO: Zone filter - so they need to be in a group in an organization
    // that passes the filter

    // Add org users to the users list
    // If there is a user reference then apply it to it
    // Otherwise they will all be separate
    // console.log("je", allStats.value.organizationUsers);

    const applyNotIncludeAll =
      !filter.value?.includeAll &&
      (user.value?.database == null || filter.value?.includeAll == false);

    const ignoreUsers =
      user.value.isAdmin && applyNotIncludeAll
        ? allStats.value.ignoreUsers
        : new Set();

    const includedUsers = new Set(
      users
        ?.filter((user) => !ignoreUsers.has(user.id))
        .map((user) => user.id) ?? []
    );

    // FIXME: Duplicated
    const haveProcedureFilter =
      (filter.value?.procedure &&
        filter.value.procedure.length &&
        Array.isArray(filter.value.procedure)) ??
      false;

    const procedureIds = haveProcedureFilter
      ? filter.value.procedure.map(
          (procedureFilter) =>
            (allStats.value.procedures ?? []).find(
              (p) => p.procedures.title == procedureFilter
            )?.procedures?.id
        )
      : [];
    // FIXME: End Duplicated

    // FIXME: Not the right way to work this out
    const organizationMapNameToZone = (
      allStats.value.organizations ?? []
    ).reduce((acc, org) => {
      acc[normalizeHospitalName(org.o.name)] = org.o.zone
        ? org.o.zone
        : defaultZoneCode(org.o.country);
      return acc;
    }, {});

    // Include all users in the map
    const userMap = (allStats.value.users ?? []).reduce((acc, value) => {
      if (value.id) {
        acc[value.id] = value;
      }
      return acc;
    }, {});

    const organizationMap = (allStats.value.organizations ?? []).reduce(
      (acc, value) => {
        if (value.id) {
          acc[value.id] = value;
        }
        return acc;
      },
      {}
    );

    // FIXME: Taken from Users.vue
    // We need to apply the group info to the users
    const userGroups = {};
    const groups = allStats.value.groups ?? [];
    groups.forEach((group) => {
      const g = group["g"];
      const organization = g.organization ?? "";
      g.admins.forEach((a) => {
        if (!userGroups[a]) {
          userGroups[a] = { organizations: {} };
        }
        if (!userGroups[a].organizations[organization]) {
          userGroups[a].organizations[organization] = {
            groups: [],
            adminGroups: [],
            mappedOrganization: organizationMap
              ? organizationMap[organization]
              : null,
          };
        }

        const org = userGroups[a].organizations[organization];
        if (g.id == org.mappedOrganization?.o?.group) {
          org.licensed = true;
        }
        org.adminGroups.push(g);
      });
      g.users.forEach((a) => {
        if (!userGroups[a]) {
          userGroups[a] = { organizations: {} };
        }
        if (!userGroups[a].organizations[organization]) {
          userGroups[a].organizations[organization] = {
            groups: [],
            adminGroups: [],
            mappedOrganization: organizationMap
              ? organizationMap[organization]
              : null,
          };
        }

        const org = userGroups[a].organizations[organization];
        if (g.id == org.mappedOrganization?.o?.group) {
          org.licensed = true;
        } else {
          org.groups.push(g);
        }
      });
    });

    // FIXME: Not great modifying the original data, but it shouldn't cause any issues
    // Just that a result should go against an organization in the data supplied to
    // reports rather than the report using the userMap
    const modResults = allStats.value.results;
    modResults?.forEach((result) => {
      result.organization =
        normalizeHospitalName(userMap[result.user]?.hospital) ?? "Unassigned";
      const ug = userGroups[result.user];
      if (ug?.organizations && Object.keys(ug.organizations).length == 1) {
        const n =
          ug.organizations[Object.keys(ug.organizations)[0]]?.mappedOrganization
            ?.o?.name;
        result.organization = normalizeHospitalName(n);
      }

      // Try to use the text name... we are not classifying group, but region
      result.zone =
        organizationMapNameToZone[
          result.organization ??
            normalizeHospitalName(userMap[result.user]?.hospital)
        ] ?? "U";
    });

    // Applies the procedure filter and the zone filter
    // but not any timing
    const haveZoneFilter = filter.value.zone;
    const results = (modResults ?? [])
      .filter(
        (result) =>
          includedUsers.has(result.user) &&
          (!haveProcedureFilter ||
            procedureIds.indexOf(result.procedure) != -1) &&
          ((!haveZoneFilter && (!applyNotIncludeAll || result.zone != "X")) ||
            result.zone == filter.value.zone)
      )
      // Remove outliers greatly affecting the output, this can be from leaving it in a session then
      // awakening it later
      .filter((result) => result.duration < 60 * 60);

    const modAttempted = allStats.value.attempted?.map((entry) => {
      return haveProcedureFilter
        ? {
            user: entry.user,
            stats: entry.stats.filter(
              (pstat) => procedureIds.indexOf(pstat.procedure) != -1
            ),
          }
        : entry;
    });
    modAttempted?.forEach((result) => {
      result.organization =
        normalizeHospitalName(userMap[result.user]?.hospital) ?? "Unassigned";
      const ug = userGroups[result.user];
      if (ug?.organizations && Object.keys(ug.organizations).length == 1) {
        const n =
          ug.organizations[Object.keys(ug.organizations)[0]]?.mappedOrganization
            ?.o?.name;
        result.organization = normalizeHospitalName(n);
      }
      result.zone = organizationMapNameToZone[result.organization] ?? "U";
    });

    // TODO: procedures

    // Need to filter out attempted too - ?
    const attempted = (modAttempted ?? [])
      .filter((result) => includedUsers.has(result.user))
      .filter(
        (result) =>
          (!haveZoneFilter && (!applyNotIncludeAll || result.zone != "X")) ||
          result.zone == filter.value.zone
      );

    if (custom?.type == "vantari_trainer") {
      const dates = custom.dates.map((d) => DateTime.fromISO(d).startOf("day"));

      allStats.value.results
        .filter(
          (value) =>
            value.user == custom.user &&
            dates.find((d) =>
              d.equals(
                DateTime.fromISO(value.start_date.replace("_", ":")).startOf(
                  "day"
                )
              )
            )
        )
        .forEach((value) => {
          value.user = "vantari";
        });
    }

    const periodResults = getPeriodResults(
      filter,
      results.filter((value) => includedUsers.has(value.user)),
      allStats
    );

    // Default is 3 months prior to the start of the period
    const referenceFilter = {
      value: {
        ...filter.value,
        zoneName:
          filter.value.zone && filteredZoneOptions.length
            ? filteredZoneOptions[0]
            : undefined,
      },
    };
    let referenceMultiplier = 1;
    let haveReference = false;

    // Using same calcs as getPeriodResults, not sure on the lastPeriod
    // reasoning
    {
      const customPeriodStart =
        lastPeriod.value == "custom" && filter.value.dateStart
          ? DateTime.fromISO(filter.value.dateStart)
          : null;

      // Very rough filtering for now - potentially should go to the start of the month / week
      const periodEnd = DateTime.now();
      const periodStart =
        customPeriodStart ??
        (lastPeriod.value == "all"
          ? null
          : lastPeriod.value == "last 12 months"
          ? periodEnd.minus({ months: 12 })
          : lastPeriod.value == "last 3 months"
          ? periodEnd.minus({ months: 3 })
          : lastPeriod.value == "last 6 months"
          ? periodEnd.minus({ months: 6 })
          : lastPeriod.value == "last 7 days"
          ? periodEnd.minus({ days: 7 })
          : periodEnd.minus({ days: 28 }));

      if (periodStart) {
        // FIXME: This is fixed, but we should declare it elsewhere (and maybe allow it to change?)
        const refPeriodStart = periodStart.minus({ months: 3 });
        referenceFilter.value.dateRange = "custom";
        referenceFilter.value.dateEnd = periodStart.toISODate();
        referenceFilter.value.dateStart = refPeriodStart.toISODate();

        // Don't do it if we don't have the data or are long way in the past
        if (
          refPeriodStart > dashboardStartQuery.value ||
          refPeriodStart < DateTime.now().minus({ months: 9 })
        ) {
          haveReference = true;

          const refHours = periodStart.diff(refPeriodStart, "hours").hours;
          // FIXME: What about a custom end?
          // And is hours the best - or days?
          const periodHours = periodEnd.diff(periodStart, "hours").hours;

          referenceMultiplier = periodHours / refHours;
        }
      }
    }

    const referenceResults = haveReference
      ? getPeriodResults(
          referenceFilter,
          results.filter((value) => includedUsers.has(value.user)),
          allStats
        )
      : null;

    // Doesn't hurt always including this, but could switch on custom?.type == "vantari_trainer"
    const extraGroups = [
      {
        g: {
          active: true,
          admins: [],
          users: ["vantari"],
          trainer: true,
          name: "Vantari Training",
          id: "vantari_training",
        },
      },
    ];

    // FIXME: Not great probably doing it in place, otherwise need to almagamate
    // allStats.value.users.forEach((u) => {
    //   u.organizationUsers = [];
    // });

    // (allStats.value.organizationUsers ?? []).forEach((ou) => {
    //   if (ou.user) {
    //     userMap[ou.user].organizationUsers.push(ou);
    //   }
    // });

    const organizationUsers = allStats.value.organizationUsers ?? [];

    // TODO: As it shouldn't happen yet
    // const unregisteredUsers = (allStats.value.organizationUsers ?? [])
    //   .filter((ou) => !ou.user)
    //   .map((ou) => ({
    //     email: ou.email,
    //     organizationUsers: [ou],
    //   }));

    // Combine stats per user, filtered by date
    // TODO: Could use meta instead of indexing

    const customPeriodStart =
      filter.value.dateRange == "custom" && filter.value.dateStart
        ? DateTime.fromISO(filter.value.dateStart)
        : null;

    const periodStart =
      customPeriodStart ??
      (filter.value.dateRange == "all" || !filter.value.dateRange
        ? null
        : lastPeriod.value == "last 12 months"
        ? DateTime.now().minus({ months: 12 })
        : lastPeriod.value == "last 3 months"
        ? DateTime.now().minus({ months: 3 })
        : lastPeriod.value == "last 6 months"
        ? DateTime.now().minus({ months: 6 })
        : lastPeriod.value == "last 7 days"
        ? DateTime.now().minus({ days: 7 })
        : DateTime.now().minus({ days: 28 }));

    const customPeriodEnd =
      filter.value.dateRange == "custom" && filter.value.dateEnd
        ? DateTime.fromISO(filter.value.dateEnd).endOf("day")
        : null;

    console.log("Log in dashboard:", logs.value);

    // Perhaps we can just use the session_dates, connect_session_dates entries and do the IP map elsewhere
    const userByLoginA = logs.value?.reduce((acc, v) => {
      // HACK: Could at least use the date!
      if (v.o.user && v.o.ipAddress) {
        acc[v.o.ipAddress] = v.o.user;
        const spl = v.o.ipAddress.split(".");
        if (spl.length == 4) {
          spl[3] = 0;
          acc[spl.join(".")] = v.o.user;
        }
      }
      return acc;
    }, {});
    const userByLogin = allStats.value.connectStats?.data?.reduce(
      (acc, entry) => {
        const ipAddress = entry[8] ?? entry[9] ?? "";
        if (ipAddress && entry[0]) {
          acc[ipAddress] = entry[0];
        }
        return acc;
      },
      userByLoginA
    );

    const periodConnectStats =
      allStats.value.connectStats?.data?.reduce((acc, entry) => {
        // in range? if(entry[1])
        const date = DateTime.fromISO(entry[2]).startOf("day");
        if (
          (!periodStart || date >= periodStart.startOf("day")) &&
          (!customPeriodEnd || date <= customPeriodEnd.startOf("day"))
        ) {
          // There should be grouping by the dimensions, hence the value can be assigned across them
          // We are filtering here so that we have only one set of data to show, it could be based on the filter criteria
          // Also missing some user IDs
          const ipAddress = entry[8] ?? entry[9] ?? "";
          // Some were saved with a .0 at the end
          const key = entry[0] == "" ? userByLogin[ipAddress] ?? "" : entry[0];

          const baseEntry = {
            sessions: 0,
            connect_sessions: 0,
            last_date: entry[2],
            session_dates: {},
            connect_session_dates: {},
            locations: {},

            siteHost: entry[3],
            siteEnv: entry[4],
            orgId: entry[5],
            siteType: entry[6],
            adminUserId: entry[7],
          };

          // Don't included assisted entry, the log of this is still great, but if displaying then it should be indicated that
          // it was assisted.
          if (!baseEntry.adminUserId) {
            if (!acc[key]) {
              acc[key] = baseEntry;
            }
            // On the assumption that the query only gives back one entry per date - it is grouped
            if (baseEntry.siteType == "connect") {
              acc[key].connect_sessions += entry[1];
              acc[key].connect_session_dates[entry[2]] = 1;
            } else {
              acc[key].sessions += entry[1];
              acc[key].session_dates[entry[2]] = 1;
            }

            // Number, name
            acc[key].locations[entry[10][1]] = 1;
            //console.log(entry[10][1]);

            // if (baseEntry.siteType == "connect") {
            //   acc[key].last_date = acc[key].last_date + "/" + entry[2];
            // }
            const ref_date = DateTime.fromISO(acc[key].last_date).startOf(
              "day"
            );
            if (date > ref_date) {
              acc[key].last_date = entry[2];
            }
          }
        }
        return acc;
      }, {}) ?? {};

    // FIXME: Consistency with any calculations above, but allows for charts to be consistent
    const now = DateTime.now();

    // Show either 6 months (even if a shorter period is selected as it gives context)
    // or 12 months as an extended view
    // We also have dashboardStartQuery.value and the current filter - prevDateRange
    const show12Months =
      lastPeriod.value == "last 12 months" ||
      filter.value?.prevDateRange == "last 12 months";
    const sinceDate = now.minus({ weeks: show12Months ? 52 : 26 });

    // Needs to filter the user's list, then also all the rest
    // FIXME: This could be querying the database better
    return {
      // Just need id, email to select them
      allUsers: (allStats.value.allUsers ?? []).map((u) => ({
        id: u.id,
        email: u.email,
      })),
      userMap: userMap,
      organizationMap: organizationMap,
      catalog: (allStats.value.catalogs ?? [])[0] ?? {},
      zones: filteredZoneOptions,
      periodResults: periodResults,
      now: now,
      sinceDate: sinceDate,
      referenceFilter: referenceFilter.value,
      referenceResults: referenceResults,
      referenceMultiplier: referenceMultiplier,
      results: { data: results },
      users: { data: users },
      procedures: { data: allStats.value.procedures },
      attempted: { data: attempted },
      groups: [...(allStats.value.groups ?? []), ...extraGroups],
      filteredGroups: filteredGroups ?? allStats.value.groups ?? [],
      filteredOrganizations: organizations.filter(
        (org) => filter.value?.includeDeactivated || !org.o.isDeactivated
      ),
      organizations: organizations,
      organizationUsers: organizationUsers,
      ignoreUsers: ignoreUsers,
      trainingModules: allStats.value.trainingModules ?? [],
      procedureIds: procedureIds,
      connectStats: Object.entries(periodConnectStats),
    };
  });

  const organizationOptions = computed(() => {
    if (!allStats.value) {
      return [];
    }

    return [
      ...new Set([
        "",
        ...(allStats.value.organizations ?? []).map((org) => org.o),
      ]),
    ].sort();
  });

  const hospitalOptions = computed(() => {
    if (!allStats.value) {
      return [];
    }

    if (!filter.value?.includeAllOrganizations) {
      return [
        ...new Set([
          "",
          ...allStats.value.organizations.map((org) => org.o.name),
        ]),
      ].sort();
    }

    const allOptions = allStats.value.users
      .map((d) => d.hospital?.trim())
      .filter((d) => d != null);

    const orgs = new Set([
      ...allStats.value.organizations.map((org) =>
        normalizeHospitalName(org.o.name)
      ),
    ]);

    // TODO: Use normalizeHospitalName, menu has multiple entries at the moment
    const onlyNewOptions = allOptions.filter(
      (name) => !orgs.has(normalizeHospitalName(name))
    );
    return [
      ...new Set([
        "",
        ...onlyNewOptions,
        ...allStats.value.organizations.map((org) => org.o.name),
      ]),
    ].sort((a, b) => a.localeCompare(b));
  });

  const procedureOptions = computed(() => {
    if (!stats.value?.procedures?.data) {
      return [""];
    }

    return [
      "",
      ...new Set(stats.value.procedures.data.map((d) => d.procedures.title)),
    ].sort();
  });

  const route = useRoute();
  const router = useRouter();

  // TODO: We should be able to get this to work
  // watch(
  //   () => route.query,
  //   () => {
  //     setFilter(route.query.value, false);
  //   }
  // );

  const defaultIncludeAll = computed(() => {
    return user.value?.database != null;
  });

  function defaultedQuery(h) {
    const query = { ...h };
    if (query.dateStart && !query.dateRange) {
      query.dateRange = "custom";
    }
    query.includeAll =
      query.includeAll == false || query.includeAll == "false"
        ? false
        : query.includeAll ?? defaultIncludeAll;
    query.includeAllOrganizations = query.includeAllOrganizations ?? true;
    if (query.procedure && !Array.isArray(query.procedure)) {
      query.procedure = [query.procedure];
    }
    return query;
  }

  function minimizedQuery(h) {
    const query = { ...h };

    if (query.includeAllOrganizations == "false") {
      query.includeAllOrganizations = false;
    } else if (query.includeAllOrganizations != false) {
      query.includeAllOrganizations = true;
    }
    if (query.includeAll == "true") {
      query.includeAll = true;
    }
    if (query.includeAll == "false") {
      query.includeAll = false;
    }
    if (query.includeDeactivated == "false") {
      query.includeDeactivated = false;
    }
    if (query.includeAllOrganizations !== false) {
      query.includeAllOrganizations = true;
    }
    if (!query.timeZone) {
      delete query.timeZone;
    }
    if (!query.hospital) {
      delete query.hospital;
    }
    if (!query.group) {
      delete query.group;
    }
    if (!query.name) {
      delete query.name;
    }
    if (!query.dateStart) {
      delete query.dateStart;
    }
    if (!query.dateEnd) {
      delete query.dateEnd;
    }
    if (!query.procedure) {
      delete query.procedure;
    }
    if (!query.includeDeactivated) {
      delete query.includeDeactivated;
    }
    //console.log("Min to", query);
    // Not sure if we want to keep it this way, but we have default as to include all
    if (query.includeAllOrganizations) {
      delete query.includeAllOrganizations;
    }
    //console.log("A", query, defaultIncludeAll.value);
    if (query.includeAll == defaultIncludeAll.value) {
      // console.log("AAA", user.value, query, defaultIncludeAll.value);
      delete query.includeAll;
    }
    //console.log("AA", query, defaultIncludeAll.value);
    if (query.dateStart && !query.dateRange) {
      query.dateRange = "custom";
    }
    if (query.procedure && !Array.isArray(query.procedure)) {
      query.procedure = [query.procedure];
    }

    if (query.dateRange != "custom" && query.prevDateRange) {
      delete query.prevDateRange;
    }

    //console.log("B", query);
    return query;
  }

  watch([assistOrganizationId, assistGroupId], () => {
    // Update the URL
    console.log("Update the URL");
    setFilter(filter.value);
  });

  function getPeriodResults(filter, results, allStats) {
    // Expect an array
    const haveProcedureFilter =
      (filter.value?.procedure &&
        filter.value.procedure.length &&
        Array.isArray(filter.value.procedure)) ??
      false;

    // Relies on the dashboard's data
    const procedureIds = haveProcedureFilter
      ? filter.value.procedure.map(
          (procedureFilter) =>
            (allStats.value.procedures ?? []).find(
              (p) => p.procedures.title == procedureFilter
            )?.procedures?.id
        )
      : [];

    if (filter.value.userFilter) {
      console.log(
        "test",
        filter.value,
        allStats.value,
        haveProcedureFilter,
        procedureIds
      );
    }

    const haveGroupFilter =
      (filter.value?.group && filter.value.group != "") ?? false;
    const requirePrivatePractice =
      filter.value?.group && filter.value.group == "Private Practice";

    // FIXME: Why was this previously lastPeriod.value == "custom"
    const customPeriodStart =
      filter.value.dateRange == "custom" && filter.value.dateStart
        ? DateTime.fromISO(filter.value.dateStart)
        : null;

    const customPeriodEnd =
      filter.value.dateRange == "custom" && filter.value.dateEnd
        ? DateTime.fromISO(filter.value.dateEnd).endOf("day")
        : null;

    // Very rough filtering for now - potentially should go to the start of the month / week
    const periodStart =
      customPeriodStart ??
      (filter.value.dateRange == "all" || !filter.value.dateRange
        ? null
        : lastPeriod.value == "last 12 months"
        ? DateTime.now().minus({ months: 12 })
        : lastPeriod.value == "last 3 months"
        ? DateTime.now().minus({ months: 3 })
        : lastPeriod.value == "last 6 months"
        ? DateTime.now().minus({ months: 6 })
        : lastPeriod.value == "last 7 days"
        ? DateTime.now().minus({ days: 7 })
        : DateTime.now().minus({ days: 28 }));

    return results
      .filter(
        (value) =>
          !haveProcedureFilter || procedureIds.indexOf(value.procedure) != -1
      )
      .filter(
        (value) =>
          !haveGroupFilter ||
          (value.group == value.user) == requirePrivatePractice
      )
      .filter(
        (value) =>
          !periodStart ||
          DateTime.fromISO(value.start_date.replace("_", ":")).startOf("day") >=
            periodStart.startOf("day")
      )
      .filter(
        (value) =>
          !customPeriodEnd ||
          DateTime.fromISO(value.start_date.replace("_", ":")).startOf("day") <=
            customPeriodEnd.startOf("day")
      );
  }

  const documentTitle = computed(() => {
    // TODO: If filtered by an organization, could include it in the title
    // Compare with the SET_BREADCRUMB_ACTION
    const query = filter.value;
    const zone_app = query.zone ? ` [${query.zone}]` : "";
    return `Vantari Connect | ${
      store.getters.pageTitle ?? "Dashboard"
    }${zone_app}`;
  });

  watch([documentTitle], () => {
    window.document.title = documentTitle.value;
  });

  // we filter at this level
  function setFilter(h, updateUrl = true) {
    // Update the filter if we have no date filter, but do have a previous filter to now be applied
    // Otherwise if were to clear it completely then there would be no date range / router would set it back
    if (!h.dateRange) {
      // Make a copy
      h = { ...h };
      h.dateRange = h.dateStart ? "custom" : h.prevDateRange ?? "last 28 days";
      delete h.prevDateRange;
    }

    if (
      h.dateRange == "custom" &&
      !h.prevDateRange &&
      filter.value.dateRange &&
      filter.value.dateRange != "custom"
    ) {
      // Make a copy
      h = { ...h };
      h.prevDateRange = filter.value.prevDateRange ?? filter.value.dateRange;
      // Going back to 28 days is default
      if (h.prevDateRange == "last 28 days") {
        delete h.prevDateRange;
      }
    }

    if (
      h.dateRange == "custom" &&
      !h.prevDateRange &&
      filter.value.prevDateRange
    ) {
      // Make a copy
      h = { ...h };
      h.prevDateRange = filter.value.prevDateRange;
    }

    const query = minimizedQuery(h);

    // If external, need to set other parameters also
    if (!updateUrl) {
      // Setting an organization requires gathering the info from the ID
      assistOrganizationId.value = h.assistOrganization;
      assistGroupId.value = h.assistGroup;
    }

    // The query has more to it than just the filter
    // Always set it as null is required to unset it
    // Which does mean it could be set separately to the filter?
    // But then this is the entry point from the router
    query.assistOrganization = assistOrganizationId.value;
    query.assistGroup = assistGroupId.value;

    // console.log("setFilter", h, query);

    const changed =
      JSON.stringify(query) != JSON.stringify(minimizedQuery(filter.value));

    // console.log(
    //   "changed",
    //   changed,
    //   JSON.stringify(query),
    //   JSON.stringify(minimizedQuery(filter.value))
    // );

    // TODO: Fix it that when we deem it as having changed constantly that we don't crash the browser window

    if (!changed) {
      return;
    }

    const queryLastPeriod = query.dateRange ?? "last 28 days";

    const customPeriodStart =
      queryLastPeriod == "custom" && query.dateStart
        ? DateTime.fromISO(query.dateStart)
        : null;

    const periodStart =
      customPeriodStart ??
      (queryLastPeriod == "all"
        ? DateTime.now().minus({ years: 4 })
        : queryLastPeriod == "last 12 months"
        ? DateTime.now().minus({ months: 12 })
        : queryLastPeriod == "last 3 months"
        ? DateTime.now().minus({ months: 3 })
        : queryLastPeriod == "last 6 months"
        ? DateTime.now().minus({ months: 6 })
        : queryLastPeriod == "last 7 days"
        ? DateTime.now().minus({ days: 7 })
        : DateTime.now().minus({ days: 28 }));

    // If it changes the name then we need to reload
    const needReload =
      !dashboardStartQuery.value ||
      periodStart < dashboardStartQuery.value ||
      (h?.name != filter.value?.name && (h?.name || filter.value?.name));

    //console.log("Compare", h, query, route.query, minimizedQuery(route.query));

    filter.value = defaultedQuery(h);

    // Update the URL if necessary
    if (updateUrl) {
      // TODO: nextTick still gives circular issues, can we resolve it in a better way?
      setTimeout(() => {
        if (
          JSON.stringify(query) != JSON.stringify(minimizedQuery(route.query))
        ) {
          // There is a scrollBehaviour on the router, it is possible to just pushState on the window,
          // but that will be lost by the router - but good to keep in case the resolve comes in useful
          // const { href } = router.resolve({ query: query });

          // Don't supply null here!!
          console.log("Replace route");
          router.replace({ query: query });
        }
      });
    } else {
      console.log("External update to the filter", query);
    }

    // Don't load if we aren't even getting results from here
    if (needReload && dashboardIncludesResults.value) {
      // HACK: throttle multiple requests
      if (hasLoadedDashboard.value) {
        hasLoadedDashboard.value = false;
        isLoadingDashboard.value = false;

        loadStats();
      }
    }
  }

  function modifyGroup(group, func) {
    axios.put("group/" + group.id, group).then((response) => {
      // Need only be groups probably, but then again there could be a clash if externally updated
      refreshOrganizations(func);
    });
  }

  function modifyGroupAdmins(group) {
    axios.put("groupAdmins/" + group.group, group.admins).then((response) => {
      // Need only be groups probably, but then again there could be a clash if externally updated
      refreshOrganizations(null);
    });
  }

  function setGroupOrganization(group) {
    console.log("Modifying groups", group);
    axios
      .put("groupOrganization/" + group.group, group.organization ?? "")
      .then((response) => {
        // Need only be groups probably, but then again there could be a clash if externally updated
        refreshOrganizations(null);
      });
  }

  function modifyUserDeleted(user) {
    let params = {};
    if (filter.value?.name) {
      params = {
        params: {
          name: filter.value.name,
        },
      };
    }

    console.log("Modifying user deleted");
    // console.log(group);
    axios
      .put("userDeleted/" + user.id, user.deleted ? true : false)
      .then((response) => {
        console.log(response);
        axios.get("users", params).then((users) => {
          const v = allStats.value;
          v.users = users.data;
          allStats.value = v;

          //func();
        });
      });
  }

  // should really be an add user - then we just update the one
  function modifyGroupUsers(group) {
    let params = {};
    if (filter.value?.name) {
      params = {
        params: {
          name: filter.value.name,
        },
      };
    }

    console.log("Modifying group users");
    const users = JSON.stringify(group.users);
    console.log(users);

    axios.put("groupUsers/" + group.id, users).then((response) => {
      // Need only be groups probably, but then again there could be a clash if externally updated
      refreshOrganizations(null);
    });
  }

  function addOrganization(org, groupAdmins, func) {
    const obj = {
      name: org.name,
      otherNames: org.otherNames,
      group: org.group,
      organizationType: org.organizationType,
      license: {
        userLicenses: org.userLicenses ?? null,
        licenseStartDate: org.licenseStartDate?.toISOString
          ? DateTime.fromISO(org.licenseStartDate.toISOString()).toISODate()
          : undefined,
        licenseEndDate: org.licenseEndDate?.toISOString
          ? DateTime.fromISO(org.licenseEndDate.toISOString()).toISODate()
          : undefined,
        licenseType: org.licenseType ? org.licenseType : undefined,
        licenseNotes: org.licenseNotes ? org.licenseNotes : undefined,
        isDeactivated: org.isDeactivated ? org.isDeactivated : undefined,
        deactivationDate:
          org.isDeactivated && org.deactivationDate?.toISOString
            ? DateTime.fromISO(org.deactivationDate.toISOString()).toISODate()
            : undefined,
      },
      groupAdmins: groupAdmins,
      country: org.country,
      zone: org.zone == "AUTO" ? "" : org.zone,

      // Used for creation. Could actually be a different name
      // groupName: org.name,
      // groupDescription: org.name,

      // This is publicly available
      // The name is as reported to the user
      organizationInfo: {
        name: org.name,
        hidePrivatePractice: org.hidePrivatePractice,
        hideOrganizationGroup: org.hideOrganizationGroup,
      },
    };

    axios.post("organizations", obj).then((response) => {
      refreshOrganizations(func);
    });
  }

  function setAssistOrganization(organization) {
    // The assistOrganization will follow
    assistOrganizationId.value = organization?.id;
    // FIXME: Should probably be .organization
    // console.log("org", organization.id);
  }

  function setAssistGroup(group) {
    assistGroupId.value = group?.id;
  }

  function modifyOrganization(org, groupAdmins, func) {
    const obj = {
      name: org.name,
      type: "organization",
      otherNames: org.otherNames,
      group: org.group,
      organizationType: org.organizationType,
      license: {
        userLicenses: org.userLicenses ?? null,
        licenseStartDate: org.licenseStartDate?.toISOString
          ? DateTime.fromISO(org.licenseStartDate.toISOString()).toISODate()
          : undefined,
        licenseEndDate: org.licenseEndDate?.toISOString
          ? DateTime.fromISO(org.licenseEndDate.toISOString()).toISODate()
          : undefined,
        licenseType: org.licenseType ? org.licenseType : undefined,
        licenseNotes: org.licenseNotes ? org.licenseNotes : undefined,
        isDeactivated: org.isDeactivated ? org.isDeactivated : undefined,
        deactivationDate:
          org.isDeactivated && org.deactivationDate?.toISOString
            ? DateTime.fromISO(org.deactivationDate.toISOString()).toISODate()
            : undefined,
      },
      groupAdmins: groupAdmins,
      country: org.country,
      zone: org.zone == "AUTO" ? "" : org.zone,

      // This is publicly available
      // The name is as reported to the user
      organizationInfo: {
        name: org.name,
        hidePrivatePractice: org.hidePrivatePractice,
        hideOrganizationGroup: org.hideOrganizationGroup,
      },
    };

    axios.put("organizations/" + org.id, obj).then((response) => {
      refreshOrganizations(func);
    });
  }

  function refreshOrganizations(func) {
    // Reload organizations and groups
    axios.all([axios.get("organizations"), axios.get("groups")]).then(
      axios.spread((organizations, groups) => {
        console.log("Got response.");

        // Validate the data so that readers can expect the fields
        groups.data.forEach((g) => {
          if (!g.g.admins?.forEach) {
            g.g.admins = [];
            g.g.error = true;
          }
        });

        const organizationMap = (organizations?.data ?? []).reduce(
          (acc, value) => {
            if (value.id) {
              acc[value.id] = value;
            }
            return acc;
          },
          {}
        );

        const v = allStats.value;
        v.organizations = organizations.data;
        v.groups = groups.data;
        v.organizationMap = organizationMap.data;

        allStats.value = v;

        if (func != null) {
          func();
        }
      })
    );
  }

  // Add a group, we also have the connect API to call through to
  // {
  //   "admins": [],
  //   "description": "Group for giving the odd demo",
  //   "id": "gr_odd_demo",
  //   "name": "Vantari Odd Demo Group",
  //   "schema": 0,
  //   "type": "group",
  //   "users": []
  // }

  function addGroup(org, func) {
    const obj = {
      name: org.name,
      description: org.description,
      type: "group",
      schema: 0,
      admins: [],
      users: [],
    };

    axios.post("groups", obj).then((response) => {
      axios.get("groups", {}).then((groups) => {
        const v = allStats.value;
        v.groups = groups.data;
        allStats.value = v;

        const result = response.data.results[0];
        func(result[Object.keys(result)[0]]);
      });
    });
  }

  // fixme - userId ??
  // this should be a patch?

  function setAsSuperAdmin(userId, superAdmin, func) {
    const obj = { superAdmin: superAdmin };
    console.log("Super admin = " + superAdmin);
    axios.put("user_settings/" + userId, obj).then((response) => {
      func();
    });
  }

  function loginAsOrganization(userId, organizationId, func) {
    const obj = { organization: organizationId };
    axios.put("user_settings/" + userId, obj).then((response) => {
      func();
    });
  }

  function deleteOrganization(org, func) {
    console.log(org);
    axios.delete("organizations/" + org.id, {}).then((response) => {
      axios.get("organizations", {}).then((organizations) => {
        const v = allStats.value;
        v.organizations = organizations.data;
        allStats.value = v;

        func();
      });
    });
  }

  function revealUser(id) {
    axios.get("reveal_user", { params: { id: id } }).then((data) => {
      // Replace in allStats
      // HACK, assume it works
      const user = data.data[0];
      allStats.value.users.forEach((element) => {
        if (element.id == user.id) {
          element.first_name = user.first_name;
          element.last_name = user.last_name;
          element.revealed = true;
        }
      });
    });
  }

  const user = computed(() => {
    return store.getters.currentUser;
  });

  // watch(user, () => {
  //   const devDatabase = user.value?.database != null;

  //   // As user.value?.database may have changed, this can affect the filer
  //   console.log("Changed, set filter", devDatabase);
  //   setFilter(filter.value);
  //   //return user.value?.database != null;
  // });

  function loadProfileOptions() {
    axios.get("profile_options", {}).then((options) => {
      profileOptions.value = options?.data;
    });
  }

  function retryLoadStats() {
    loadStats(dashboardIncludesResults.value, true);
  }

  // We gather everything from the server into allStats, however the results will take substantially longer to load
  // TODO: Avoid the duplicate reloading we can currently have

  // Minimum load doesn't include results
  // TODO: Check reliability, cancelling, combining, better name
  function loadStats(includeResults = true, forceLoad = false) {
    // console.trace("Attempt loading dashboard...", includeResults);
    // includeResults = true;

    if (forceLoad) {
      isLoadingDashboard.value = false;
      hasLoadedDashboard.value = false;
      isLoadingError.value = false;
    }

    if (isLoadingError.value) {
      return;
    }

    if (includeResults && !dashboardIncludesResults.value) {
      hasLoadedDashboard.value = false;
      dashboardIncludesResults.value = true;
    }

    // Only load once - and we do all documents at once
    // Strictly speaking, there could be some separation, but no need for our current purposes.
    // It is easier to just keep it all synchronized
    if (hasLoadedDashboard.value || isLoadingDashboard.value) {
      return;
    }

    if (!store.getters.isUserAuthenticated) {
      return;
    }

    const includingResults = dashboardIncludesResults.value;

    isLoadingDashboard.value = true;
    isLoadingError.value = false;

    // It is definitely faster to query 6 months or 28 days, however Active Users presents for 12 months.
    // Active / New Users requires going back to the start of the month from 6 months ago.
    // In any case, no need to go to 7 days.
    const periodStart =
      filter.value?.customPeriodStart ?? lastPeriod.value == "all"
        ? DateTime.now().minus({ years: 4 })
        : DateTime.now().minus({ months: 13 });

    dashboardStartQuery.value = periodStart.minus({ days: 1 });

    console.log("Loading dashboard, includingResults =", includingResults);

    const dashboardParams = {
      params: {
        // Even as time goes on, this start date will be in range, unless we change timezone, so let's allow for that
        start_date: dashboardStartQuery.value.toISODate(),
      },
    };

    let params = {};
    if (filter.value?.name) {
      params = {
        params: {
          name: filter.value.name,
        },
      };
    }

    const connectStatsParams = {
      params: {
        start_date: dashboardStartQuery.value.toISODate(),
        end_date: DateTime.now().plus({ days: 1 }).toISODate(),
      },
    };

    // HACK: This is for connect stats, ideally wouldn't be done, particularly as it requires being admin
    axios.get("logs").then((logsResponse) => {
      logs.value = logsResponse?.data ?? [];
    });

    // There is a chance that this times out - particularly on the dashboard
    // If any fails then we display an error with a retry button which will just call this method again
    // and quite probably fail but at last there is an option
    // eg all results was taking 8.41 sec locally so more on the server
    // Long-term we definitely don't want it to fail and would retry if it was just a connection issue
    // However this isn't too much effort to insert

    // We could also vary the query based on isAdmin.value
    axios
      .all([
        axios.get("procedures", {}),
        axios.get("training_modules", {}),
        axios.get("catalogs", {}),
        axios.get("organizations", {}),
        axios.get("organizationUsers", {}),
        axios.get("groups", {}),
        // FIXME: This came back with a 502 and brought down the page
        // Can use Promise.all also
        axios.get("connect_stats", connectStatsParams).catch(() => {
          console.log("Error getting connect stats");
          return { data: null };
        }),
        ...(includingResults
          ? [
              axios.get("dashboard", dashboardParams),
              axios.get("users", params),
              axios.get("attempted", {}),
              ...(filter.value?.name ? [axios.get("users")] : []),
            ]
          : []),
      ])
      .then(
        axios.spread(
          (
            procedures,
            trainingModules,
            catalogs,
            organizations,
            organizationUsers,
            groups,
            connectStats,
            results,
            users,
            attempted,
            allUsers
          ) => {
            const ignoreUsers = new Set(
              (users?.data ?? [])
                .filter((u) => u.email.endsWith("@vantarivr.com"))
                .map((u) => u.id)
            );

            // Adjust hospital names as per the organizations list
            const aliasMap = organizations.data
              .filter((o) => o.o)
              .reduce((acc, value) => {
                acc[normalizeHospitalName(value.o.name)] = value.o.name;
                value?.o?.otherNames?.forEach((alias) => {
                  acc[normalizeHospitalName(alias)] = value.o.name;
                });
                return acc;
              }, {});

            (users?.data ?? []).forEach((user) => {
              if (user.hospital) {
                const alias = aliasMap[normalizeHospitalName(user.hospital)];
                if (alias) {
                  user.hospital = alias;
                  user.isVerified = true;
                }
              }
            });

            // Validate the data so that readers can expect the fields
            groups.data.forEach((g) => {
              if (!g.g.admins?.forEach) {
                g.g.admins = [];
                g.g.error = true;
              }
            });

            allStats.value = {
              procedures: procedures.data.filter(
                (p) =>
                  p.procedures.state == "active" ||
                  p.procedures.state == "tutorial"
              ),
              connectStats: connectStats?.data,
              results: results?.data,
              users: users?.data,
              allUsers: (allUsers ?? users)?.data,
              attempted: attempted?.data,
              groups: groups?.data,
              organizations: organizations?.data,
              organizationUsers: organizationUsers?.data,
              trainingModules: trainingModules?.data,
              catalogs: catalogs?.data,
              ignoreUsers,
            };

            isLoadingDashboard.value = false;
            hasLoadedMinimal.value = true;

            // Could be only setting this to loaded if including results makes it easier for other modules to not have
            // missing data, ie = includingResults
            hasLoadedDashboard.value = true;

            // FIXME: Must be improveable
            if (dashboardIncludesResults.value && !includingResults) {
              hasLoadedDashboard.value = false;
              loadStats();
            }
          }
        )
      )
      .catch((err) => {
        const idx =
          err?.response?.data?.errorMessage?.indexOf("Task timed out");

        // Give a more specific response if it is a timing issue, this is very much dependent on using lambdas and the response, but it is very much
        // the case for now.
        loadingErrorMessage.value = "Server did not respond correctly.";
        if (idx) {
          loadingErrorMessage.value =
            err.response.data.errorMessage.substring(idx);
        }
        isLoadingDashboard.value = false;
        isLoadingError.value = true;
        console.log(loadingErrorMessage.value, err);
      });
  }

  // active organization

  const organization = computed(() => {
    return stats.value &&
      (filter.value?.hospital || !user.value?.isAdmin) &&
      stats.value.organizations?.length
      ? stats.value.organizations[0]
      : null;
  });

  function deleteTraining(groupId, func) {
    axios.delete("training_modules/" + groupId).then((response) => {
      axios.get("training_modules", {}).then((trainingModules) => {
        const v = allStats.value;
        v.trainingModules = trainingModules.data;
        allStats.value = v;

        func();
      });
    });
  }

  function setCatalog(data, func) {
    axios.put("catalogs/default", data).then((response) => {
      axios.get("catalogs", {}).then((catalogs) => {
        const v = allStats.value;
        v.catalogs = catalogs.data;
        allStats.value = v;

        func();
      });
    });
  }

  // Sets the main for now
  function setTraining(groupId, training, func) {
    console.log("Training = " + training);
    axios.put("training_modules/" + groupId, training).then((response) => {
      axios.get("training_modules", {}).then((trainingModules) => {
        const v = allStats.value;
        v.trainingModules = trainingModules.data;
        allStats.value = v;

        func();
      });
    });
    // console.log(allStats.value.trainingModules[0]);
    // allStats.value.trainingModules[0].targets.push(training);

    // const v = allStats.value;
    // v.trainingModules[0].o = training; //.o.targets = [training];
    // allStats.value = v;

    // // console.log(v.trainingModules, "DONE");

    // func();
  }

  const userTrainingModules = computed(() => {
    // See computed organization - ultimately we select the first organization as there should only be one
    // However we have some vantari users spread across organizations.
    // Training goals should definitely show for all, anything else should require selecting the organization (maybe saving in preferences or the URL)

    // PROBLEM: With multiple organizations, this doesn't work - so can we combine between organizations?
    // But will that create a problem for modification?

    // TODO: Shouldn't the modules already be filtered?
    // console.log("tm", stats.value?.trainingModules);
    // console.log("orgs", allStats.value?.organizations);
    const existing = stats.value?.trainingModules?.filter((training) =>
      (allStats.value?.organizations ?? [])?.find(
        (organization) =>
          training.id == "training_module::" + organization.o.group
      )
    );

    const allTargets = (existing ?? []).reduce((acc, value) => {
      acc.push(...value.o.targets);
      return acc;
    }, []);

    // console.log("WE HAVE");
    // console.log(stats.value?.trainingModules);
    // console.log(organization.value);
    // console.log(existing);

    // const selected = existing?.o ?? {
    //   adminGroup: organization.value?.o.group,
    //   targets: [],
    //   links: [],
    // };

    return {
      // selected: selected,
      // adminGroup: selected?.adminGroup,
      // links: selected?.links ?? [],
      targets: allTargets.map((target) => ({
        completionRequired: target.completionRequired,
        procedure: target.procedure,
        fromDate: target.fromDate,
        endDate: target.endDate,
        id: target.id,
        procedureName:
          stats.value?.procedures.data.find(
            (procedure) => procedure.procedures.id == target.procedure
          )?.procedures?.title ?? "",
      })),
    };
  });

  const trainingModule = computed(() => {
    if (!organization.value) {
      return null;
    }

    // See computed organization - ultimately we select the first organization as there should only be one
    // However we have some vantari users spread across organizations.
    // Training goals should definitely show for all, anything else should require selecting the organization (maybe saving in preferences or the URL)

    // PROBLEM: With multiple organizations, this doesn't work - so can we combine between organizations?
    // But will that create a problem for modification?
    const existing = stats.value?.trainingModules?.find(
      (training) =>
        training.id == "training_module::" + organization.value?.o.group
    );

    // console.log("WE HAVE");
    // console.log(stats.value?.trainingModules);
    // console.log(organization.value);
    // console.log(existing);

    const selected = existing?.o ?? {
      adminGroup: organization.value?.o.group,
      targets: [],
      links: [],
    };

    return {
      selected: selected,
      adminGroup: selected?.adminGroup,
      links: selected?.links ?? [],
      targets: (selected?.targets ?? []).map((target) => ({
        completionRequired: target.completionRequired,
        procedure: target.procedure,
        fromDate: target.fromDate,
        endDate: target.endDate,
        id: target.id,
        procedureName:
          stats.value?.procedures.data.find(
            (procedure) => procedure.procedures.id == target.procedure
          )?.procedures?.title ?? "",
      })),
    };
  });

  function setPresentation(p) {
    presentation.value = { ...presentation.value, ...p };
  }

  return {
    loadStats,
    retryLoadStats,
    stats,
    filter,
    user,
    isAdmin,
    sinceDate,
    lastPeriod,
    formattedPeriod,
    setFilter,
    addOrganization,
    modifyGroupAdmins,
    modifyUserDeleted,
    modifyOrganization,
    deleteOrganization,
    loginAsOrganization,
    setAsSuperAdmin,
    addGroup,
    hospitalOptions,
    organizationOptions,
    procedureOptions,
    revealUser,
    isLoadingDashboard,
    hasLoadedDashboard,
    assistOrganization,
    setAssistOrganization,
    modifyGroupUsers,
    organization,
    trainingModule,
    userTrainingModules,
    setTraining,
    deleteTraining,
    setCatalog,
    defaultIncludeAll,
    loadProfileOptions,
    profileOptions,
    setGroupOrganization,
    modifyGroup,
    setAssistGroup,
    assistGroup,
    getPeriodResults,
    allStats,
    dashboardIncludesResults,
    formattedPeriodShort,
    formattedPeriodDetails,
    presentation,
    setPresentation,
    isLoadingError,
    loadingErrorMessage,
  };
});
