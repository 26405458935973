import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import router from "@/router/index";

export interface User {
  id: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;

  first_name: string;
  last_name: string;

  canEnterAdmin: boolean;
  isDashboardUser: boolean;
  isOrganizationUser: boolean;

  showOrganization: boolean;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

function stgCreateCookie(a, b, c) {
  let d = "";
  let f = "";
  if (c) {
    const e = new Date();
    e.setTime(e.getTime() + 24 * c * 60 * 60 * 1e3),
      (d = "; expires=" + e.toUTCString());
    f = "; SameSite=Strict";
  }
  document.cookie = a + "=" + b + d + f + "; path=/";
}

function assignDataLayer(database, userId, org, adminUserId) {
  // It is better to have the text entry
  if (!database) {
    database = "production";
  }
  const data = {
    customerId: (userId == "" ? undefined : userId) ?? undefined,
    siteType: "dashboard",
    siteEnv: process.env.NODE_ENV,
    siteHost: document.location.host,
    database: (database == "" ? undefined : database) ?? undefined,
    adminUserId: (adminUserId == "" ? undefined : adminUserId) ?? undefined,
    orgId: (org == "" ? undefined : org) ?? undefined,
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);

  // org not present at this stage
  const cookieValue = [database, userId, org, adminUserId ?? ""].join(".");
  stgCreateCookie("vantari.1.last_user", cookieValue, 1);
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  localSettings = JSON.parse(
    sessionStorage.getItem("vantari_dashboard_settings") ?? "{}"
  );

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Get mode
   * @returns User
   */
  get isIndividualUser(): boolean {
    //console.log("check", this.user?.isOrganizationUser);
    return !(
      (this.user?.showOrganization ??
        this.localSettings.showOrganization ??
        true) &&
      this.user?.isOrganizationUser
    );
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    JwtService.saveToken(user.api_token);

    // Non personal settings could be saved off here to give a better view when the page reloads
    sessionStorage.setItem(
      "vantari_dashboard_settings",
      JSON.stringify({ showOrganization: user.showOrganization })
    );

    // FIXME: Is this the right place
    // if (this.user.id) {
    //   useUserStore().loadStats(this.user.id);
    // }
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("login", credentials)
      .then(({ data }) => {
        if (window._paq) {
          window._paq.push(["resetUserId"]);
        }

        assignDataLayer(data.database, data.id, "", data.adminUserId);
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    // We should actually logout
    ApiService.post("logout", {}).then(() => {
      if (this.isUserAuthenticated) {
        if (window._paq) {
          window._paq.push(["resetUserId"]);
        }
        this.context.commit(Mutations.PURGE_AUTH);
        stgCreateCookie("vantari.1.last_user", "", -1);

        // Force a full page reload, the easiest way to make sure all
        // components reload and nothing state/sensitive is left behind
        // Does cause the screen to flash, we can put up with that for now.
        console.log("Logout - reload");
        window.location.reload();
      }
    });
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("verify_token", payload)
        .then(({ data }) => {
          // Standard users should not see the dashboard view unless their use case has been considered, and an appropriate view is created.
          // Also, an admin user may be a normal user too, so likely there needs to be a menu switch between the views.
          // It used to depend on whether we were a dashboard user, ie data.isDashboardUser, but we are allowing anyone now, and isDashboardUser
          // is reflective of an organizational login
          {
            this.context.commit(Mutations.SET_AUTH, data);

            if (window._paq) {
              window._paq.push(["resetUserId"]);
            }
            if (data) {
              // UserManagement.setUserId(data.id);
              assignDataLayer(data.database, data.id, "", data.adminUserId);
            }

            const isAdminPath =
              router.currentRoute.value.fullPath.startsWith("/a");

            // If on an admin path but we are not a dashboard user, then change our location here.
            // This may be after the router has loaded the page, so temporarily there will be a wrong view.
            if (isAdminPath && !data.isDashboardUser) {
              router.push({ name: "userDashboard" });
              //window.location.reload();
            } else if (
              router.currentRoute.value.meta.superAdminOnly &&
              !data.isAdmin
            ) {
              router.push({ name: "adminDashboard" });
            }

            // if (isAdminPath && !data.isDashboardUser) {
            //   router.push({ name: "userDashboard" });
            //   //window.location.reload();
            // }

            const isVantariPath =
              router.currentRoute.value.fullPath.startsWith("/v/");

            // Why is http://localhost:8080/#/a/organizations redirecting?

            // Here admin is super admin
            if (isVantariPath && !data.isAdmin) {
              router.push({ name: "userDashboard" });
            }

            // const individualOnly =
            //   router.currentRoute.value?.meta.individualOnly;

            // console.log("indi only?", individualOnly);
            // console.log("received auth", useRoute(), router.getRoutes());
            // // #/userDashboard
            // console.log("received auth", data);
            // //if(window.location.hash == "#/userDashboard" && )
            // //data.showOrganization
            // console.log("received auth", window.location.hash);
          }
          //  else {
          //   this.context.commit(Mutations.PURGE_AUTH);
          //   console.log("Not a dashboard user - reload");
          //   window.location.reload();
          // }
        })
        .catch(({ response }) => {
          // TODO: If we had just logged in then we should probably indicate the error here
          this.context.commit(Mutations.SET_ERROR, response?.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
          console.log("Error verifying - reload");
          window.location.reload();
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
