import axios from "axios";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { DateTime } from "luxon";
import { useDashboardStore } from "@/store/modules/DashboardModule";
import moment from "moment";
import { storeToRefs } from "pinia";

export const useUserStore = defineStore("user", () => {
  const dashboardStore = useDashboardStore();

  const sinceDate = ref("");

  const allUserStats = ref<any>();

  // TODO: Establish what this means, ie any further filter parameters that make sense for a user
  // Otherwise we're just going to use the dashboard filter
  const userFilter = ref<any>();

  const isLoadingUserData = ref<boolean>(false);

  const hasLoadedUserDashboard = ref<boolean>(false);

  const store = useStore();

  const lastPeriod = computed(() => {
    return userFilter.value?.dateRange ?? "last 28 days";
  });

  const isAdmin = computed(() => {
    return user.value.isAdmin;
  });

  const userStats = computed(() => {
    // Can't generate stats until the dashboard and user stats are ready
    if (!allUserStats.value || !hasLoadedDashboard.value) {
      return null;
    }

    const periodResults = dashboardStore.getPeriodResults(
      filter,
      allUserStats.value.results,
      allStats
    );

    // Needs to filter the user's list, then also all the rest
    // FIXME: Does this need to query the database better?
    return {
      periodResults: periodResults,
      results: { data: allUserStats.value.results },
    };
  });

  const { allStats, filter, hasLoadedDashboard } = storeToRefs(dashboardStore);

  // we filter at this level
  function setFilter(h) {
    // TODO: There is processing required here
    userFilter.value = { ...h, userFilter: true };
  }

  function verifyEmail(userId, func) {
    const obj = { user: userId };
    axios.post("verify_email", obj).then((response) => {
      func();
    });
  }

  function setUserHospital(userId, hospital, func) {
    const obj = { user: userId, hospital: hospital };
    axios.post("set_user_hospital", obj).then((response) => {
      func();
    });
  }

  function assistUser(userId, func) {
    console.log("Assist");
    const obj = { assistUserId: userId };

    axios.post("assist", obj).then((response) => {
      func();
    });
  }

  function showIndividualDashboard(func) {
    console.log(user.value);
    // This removes any super admin setting
    const obj = { showOrganization: false };
    console.log("Show organization = " + obj.showOrganization);
    axios.put("user_settings/" + user.value.id, obj).then((response) => {
      func();
    });
  }

  function showOrganizationDashboard(func) {
    console.log(user.value);
    // This removes any super admin setting
    const obj = { showOrganization: true };
    console.log("Show organization = " + obj.showOrganization);
    axios.put("user_settings/" + user.value.id, obj).then((response) => {
      func();
    });
  }

  const user = computed(() => {
    return store.getters.currentUser;
  });

  // This is the default, but we may choose to bring up an individual's results whilst in organization mode.
  // In that case we reuse this module, and do not expect two individuals to be viewed at once.
  // This defaults false, but will be set by the router if on an individual route
  const trackingCurrentUser = ref(true);

  watch([user, trackingCurrentUser], () => {
    if (trackingCurrentUser.value && user.value.id) {
      loadUserStats(user.value.id);
    }
  });

  let lastRequestedUserId = null;

  function loadCurrentUserStats() {
    trackingCurrentUser.value = true;
  }

  function loadStats(userId) {
    trackingCurrentUser.value = false;
    loadUserStats(userId);
  }

  function loadUserStats(userId) {
    if (!userId) {
      return;
    }

    // Checking isLoadingUserData.value - need a refresh in here somewhere

    // Only load once - and we do all documents at once
    // Strictly speaking, there could be some separation, but no need for our current purposes.
    // It is easier to just keep it all synchronized
    if (userId == lastRequestedUserId) {
      // FIXME: Otherwise, actually want to cancel / ignore the request
      return;
    }

    if (!store.getters.isUserAuthenticated) {
      return;
    }

    // Also load the information from the dashboard that we rely upon
    dashboardStore.loadStats(false);

    // console.trace("Loading user stats for", userId);

    isLoadingUserData.value = true;
    lastRequestedUserId = userId;

    console.log("Loading user data for", userId);

    // We could also vary the query based on isAdmin.value
    axios.all([axios.get("userDetails/" + userId, {})]).then(
      axios.spread((results) => {
        if (userId != lastRequestedUserId) {
          return;
        }

        allUserStats.value = {
          results: results.data,
        };

        isLoadingUserData.value = false;

        // TODO: Make it 6 months ago
        // TODO: Make backend also do 6 months ago, allow across years
        // TODO: Make the 28 days configurable here
        sinceDate.value = moment("01", "MM").format("MMMM");
        hasLoadedUserDashboard.value = true;
      })
    );
  }

  return {
    loadStats,
    userStats,
    user,
    isAdmin,
    sinceDate,
    lastPeriod,
    setFilter,
    isLoadingUserData,
    assistUser,
    verifyEmail,
    setUserHospital,
    showIndividualDashboard,
    showOrganizationDashboard,
    loadCurrentUserStats,
  };
});
