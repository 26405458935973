import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";

const pinia = createPinia();
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use(pinia);

app.mount("#app");

// Given a cookie key `name`, returns the value of
// the cookie or `null`, if the key is not found.
function getCookie(name: string): string {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || ""
  );
}

// This is a bit nicer than in the index.html
// database.userid.orgid.userassistid
const userInfo = getCookie("vantari.1.last_user");

// Annoyingly the implementation of createFirstTracker is lousy as one of the methods it applies uses domainFixup
// which can't handle a null and so causes an error.
const [database, userId, org, adminUserId] = (userInfo ?? "").split(".");
const data = {
  customerId: userId === undefined || userId == "" || !userId ? "" : userId,
  siteType: "dashboard",
  siteEnv: process.env.NODE_ENV,
  siteHost: document.location.host,
  database: (database == "" ? undefined : database) ?? undefined,
  adminUserId: (adminUserId == "" ? undefined : adminUserId) ?? undefined,
  orgId: (org == "" ? undefined : org) ?? undefined,
};

window.dataLayer = window.dataLayer || [];
window.dataLayer.push(data);
// There is a tag in the setup which runs script that would use window._paq to:
// _paq.push(['setUserId', userId]);
// _paq.push(['setCustomDimensionValue', 7, {{ Admin User Id }}]); etc
// See Overview, tag name Piwik PRO, click on the blue arrow
// Also see https://vantarivr.piwik.pro/analytics/#/bb983288-4102-4134-b246-8e87f5347eaf/settings/custom-dimensions/
// We need a variable using the data layer, and then a custom dimension, which the tag will assign

// This would be another way of doing it
// const customDimensionData = [
//   data.userId,
//   data.database,
//   data.siteHost,
//   data.siteEnv,
//   data.orgId,
//   data.adminUserId,
// ];
// for (let j = 0; j < customDimensionData.length; ++j) {
//   window._paq.push([
//     "setCustomDimensionValue",
//     j + 1,
//     customDimensionData[j],
//   ]);
// }

if (window.initTracking) {
  window.initTracking();
}
